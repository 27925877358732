const transformEuroM = value => {
  if (!value || value === '0.0') {
    return '-';
  }

  if (value !== 'n.m.') {
    return `€${value}m`;
  } else {
    return value;
  }
};

export default transformEuroM;
